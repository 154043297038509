import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { TranslocoPipe } from "@jsverse/transloco";
import { ServiceCenterLocation } from "@cg/core/interfaces";
import { CtalinkVariation, IconComponent } from "@cg/core/ui";
import { arrowBigGrayIcon, arrowsIcon } from "@cg/icon";
import { Ctalink, FormatTelLinkPipe, PhoneNumber } from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { LocationsFacade } from "../../+state/locations.facade";
import { LocationsearchResultlist } from "../../interfaces/locationsearch-resultlist.interface";
import { LocationsearchResultlistItem } from "../../interfaces/locationsearch-resultlist-item.interface";
import { LocationNoResultsComponent } from "../location-no-results/location-no-results.component";
import { LocationResultListItemComponent } from "../location-result-list-item/location-result-list-item.component";
@Component({
  selector: "cg-location-result-list",
  templateUrl: "./location-result-list.component.html",
  styleUrls: ["./location-result-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, LocationResultListItemComponent, IconComponent, LocationNoResultsComponent],
  providers: [FormatTelLinkPipe]
})
export class LocationResultListComponent implements OnInit {
  public readonly PAGE_SIZE = 5;

  public icon: Icon = arrowsIcon;
  public serviceCenters: LocationsearchResultlist;
  public maxPage: number;
  public pageItems: LocationsearchResultlistItem[];
  public showBackwardButton: boolean;
  public showForwardButton: boolean;
  public hasResultsInRadius: boolean;
  public hasLoadedServiceCenters: boolean;

  public get currentPage(): number {
    return this._currentPage;
  }

  public set currentPage(value: number) {
    this._currentPage = value;

    const startItem = this._currentPage * this.PAGE_SIZE;
    const endItem = (this._currentPage + 1) * this.PAGE_SIZE;
    this.pageItems = this.serviceCenters.items.slice(startItem, endItem);

    this.showBackwardButton = this._currentPage !== 0;
    this.showForwardButton = this._currentPage + 1 < this.maxPage && this.maxPage > 1;
    this.locationsFacade.setCurrentPage(this._currentPage);
  }

  private _currentPage: number;

  public constructor(
    private readonly locationsFacade: LocationsFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly formatTelLinkPipe: FormatTelLinkPipe
  ) {}

  public ngOnInit(): void {
    const serviceCenterLocations$ = this.locationsFacade.serviceCenterLocations$;
    const currentPage$ = this.locationsFacade.currentPage$;
    const isServiceCenterClosed$ = this.locationsFacade.isGivenServiceCenterClosed$;
    const hasLoaded$ = this.locationsFacade.hasLoaded$;

    combineLatest([serviceCenterLocations$, currentPage$, isServiceCenterClosed$, hasLoaded$]).subscribe(
      ([serviceCenterLocations, currentPage, isGivenServiceCenterClosed, hasLoaded]: [
        ServiceCenterLocation[],
        number,
        { costCenter: string; isScClosed: boolean },
        boolean
      ]) => {
        this.serviceCenters = this.transformToResultlistItem(serviceCenterLocations, isGivenServiceCenterClosed);
        this.maxPage = Math.ceil(serviceCenterLocations.length / this.PAGE_SIZE);
        this.currentPage = currentPage;
        this.hasLoadedServiceCenters = hasLoaded;
        this.cdr.markForCheck();
      }
    );
  }

  public transformToResultlistItem(
    content: ServiceCenterLocation[],
    isGivenServiceCenterClosed: { costCenter: string; isScClosed: boolean }
  ): LocationsearchResultlist {
    const primaryCtalink: Ctalink = {
      id: "appointmentCta",
      icon: this.icon,
      link: {
        text: "location.arrangeAppointment",
        href: null,
        title: "location.arrangeAppointment",
        routerLink: false
      },
      variation: CtalinkVariation.PRIMARY,
      ngTemplate: "cgCtalink"
    };

    const detailCtalink: Ctalink = {
      id: "detailsCta",
      icon: this.icon,
      link: {
        text: "location.detailsLink",
        href: null,
        title: "location.detailsLink",
        routerLink: false
      },
      variation: CtalinkVariation.SECONDARY,
      ngTemplate: "cgCtalink"
    };

    const telCtalink: Ctalink = {
      id: "tel",
      icon: this.icon,
      link: {
        text: PhoneNumber.DEFAULT,
        href: this.formatTelLinkPipe.transform(PhoneNumber.DEFAULT),
        title: PhoneNumber.DEFAULT,
        routerLink: false
      },
      variation: CtalinkVariation.SECONDARY,
      ngTemplate: "cgCtalink"
    };

    const tempList: LocationsearchResultlist = { items: [] };

    for (const item of content) {
      const commonObj: LocationsearchResultlistItem = {
        expansionPanel: {
          icon: arrowBigGrayIcon,
          headline: item.city + ", " + item.street
        },
        costCenter: item.costCenter,
        latitude: item.latitude,
        longitude: item.longitude,
        expanded: false, // TODO
        address: item.street + " " + item.streetNumber + ", " + item.postalCode + " " + item.city,
        primaryCtalink,
        detailCtalink,
        telCtalink,
        hintText: "location.result.hint",
        distance: item.distance ? Math.round(item.distance) : null
      };

      if (isGivenServiceCenterClosed?.costCenter === item.costCenter && isGivenServiceCenterClosed?.isScClosed) {
        delete commonObj.primaryCtalink;
      }

      tempList.items.push(commonObj);
    }

    return tempList;
  }

  public clicked(serviceCenter: LocationsearchResultlistItem): void {
    this.locationsFacade.checkIfScIsClosedAndToggle(serviceCenter.costCenter);
  }

  public goBackward(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  public goForward(): void {
    this.showBackwardButton = true;

    if (this.currentPage + 1 < this.maxPage) {
      this.currentPage++;
    }
  }
}
